import axios from "axios";
import Fuse from "fuse.js";
import { v4 as uuidv4 } from "uuid";
import Resizer from "react-image-file-resizer";

import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

import { _currencyMap } from "../util/commonData";
import { idbPendingReqs } from "../util/indexedDB";
import { _findIdxById, buildFormData } from "../util/commonFunctions";

const getLsValues = () => {
    if (typeof window.localStorage !== "undefined") {
        let k = { storage: window.localStorage };
        window.localStorage.token && (k.token = window.localStorage.token);
        window.localStorage.role && (k.role = window.localStorage.role);
        window.localStorage.roles && (k.roles = window.localStorage.roles);
        window.localStorage.user_id && (k.user_id = window.localStorage.user_id);
        window.localStorage.user_name && (k.user_name = window.localStorage.user_name);
        window.localStorage.is_tnc_accepted && (k.is_tnc_accepted = window.localStorage.is_tnc_accepted);
        window.localStorage.is_tnc_mail_sent && (k.is_tnc_mail_sent = window.localStorage.is_tnc_mail_sent);
        window.localStorage.is_tnc_page_visble && (k.is_tnc_page_visible = window.localStorage.is_tnc_page_visble);
        window.localStorage.tncReloadDone && (k.tncReloadDone = window.localStorage.tncReloadDone);
        window.localStorage.user && JSON.parse(window.localStorage.user) && typeof JSON.parse(window.localStorage.user) === "object" && (k.user = JSON.parse(window.localStorage.user));
        window?.localStorage?.gia && JSON.parse(window.localStorage.gia) && typeof JSON.parse(window.localStorage.gia) === "object" && (k.gia = JSON.parse(window.localStorage.gia));
        return k;
    }
};

const _existAndGetLsValue = (keys = []) => {
    if (typeof window.localStorage !== "undefined") {
        let obj = {};
        if (keys && typeof keys === "string") {
            if (window.localStorage.hasOwnProperty(keys)) {
                obj[keys] = window.localStorage[keys];
            }
        } else if (keys && Array.isArray(keys) && keys.length) {
            for (let i = 0; i < keys.length; i++) {
                if (window.localStorage.hasOwnProperty(keys[i])) obj[keys[i]] = window.localStorage[keys[i]];
            }
        }
        return obj;
    }
};

const _setLsValues = (ls_obj = {}) => {
    if (typeof window.localStorage !== "undefined") {
        for (let i = 0; i < Object.keys(ls_obj).length; i++) {
            window.localStorage[Object.keys(ls_obj)[i]] = ls_obj[Object.keys(ls_obj)[i]];
        }
        return window.localStorage;
    }
    return {};
};

const _inView = (element, ctop, cleft, cbottom, cright) => {
    if (!element || !element.getBoundingClientRect) return { complete: false, partial: false, custom: false };
    var element = element,
        elementRect = element.getBoundingClientRect(),
        eHeight = elementRect.height,
        eWidth = elementRect.width,
        dHeight = document.innerHeight || document.documentElement.clientHeight,
        dWidth = document.innerWidth || document.documentElement.clientWidth,
        ctop = ctop || 64,
        cleft = cleft || 64,
        cbottom = cbottom || eHeight + 2 * dHeight,
        cright = cright || eWidth + 2 * dWidth;

    return {
        complete: elementRect.top >= 0 - ctop && elementRect.left >= 0 - cleft && elementRect.bottom <= eHeight + dHeight && elementRect.right <= eWidth + dWidth,
        partial: elementRect.top >= 0 - eHeight - dHeight && elementRect.left >= 0 - eWidth - dWidth && elementRect.bottom <= eHeight + 2.8 * dHeight && elementRect.right <= eWidth + 2.8 * dWidth,
        custom: elementRect.top >= ctop && elementRect.left >= cleft && elementRect.bottom <= cbottom && elementRect.right <= cright,
    };
};

const _debounce = (func, delay) => {
    let dbTimer;
    return function () {
        const con = this,
            arg = arguments;
        clearTimeout(dbTimer);
        dbTimer = setTimeout(() => {
            func.apply(con, arg);
        }, delay);
    };
};

const _lazyloadImages = () => {
    let images = document.querySelectorAll(".image[src='/1.png']");
    for (let k = 0; k < images.length; k++) {
        _inView(images[k]).partial && images[k].setAttribute("src", images[k].getAttribute("data-src"));
    }
};

const _getApiResponseWithSearch = ({ api, apiType, baseUrlType, searchTerm = "", searchKey, size = 10, page = 1, params, blockQueryParams }) => {
    try {
        return axios[apiType](`${getBaseUrl(baseUrlType)}${api}${blockQueryParams ? "" : `?${searchKey}=${searchTerm}&size=${size}&page=${page}`}`, { ..._getConfig(), params });
    } catch (error) {
        return new Promise((resolve, reject) => {
            reject("NO VALUE FOUND FOR " + api);
        });
    }
};

const _postApiResponseWithSearch = ({ api, apiType, baseUrlType, size = 10, page = 1, body, bearerToken, blockQueryParams }) => {
    try {
        return axios[apiType](`${getBaseUrl(baseUrlType)}${api}${!blockQueryParams ? `?size=${size}&page=${page}` : ``}`, body, _getConfig(bearerToken ? false : true));
    } catch (error) {
        return new Promise((resolve, reject) => {
            reject("NO VALUE FOUND FOR " + api + " error-" + error);
        });
    }
};

const setDevUrl = (key, value) => {
    window.localStorage.setItem(key, value);
};

const getEnvType = () => {
    let env = "portal";
    if (window.location.href.includes("devportal") || window.location.href.includes("//localhost:") || window.location.href.includes("192.168.")) {
        env = "dev";
    } else if (window.location.href.includes("qaportal")) {
        env = "qa";
    }
    return env;
};

// Environment toggle - Set to true for production, false for development
const IS_PRODUCTION = true;

const getBaseUrl = (type = "cataloguing") => {
    let mapping = {
        goodcat: ["goodcatalog", "goodcatalog-dev", "goodcatalog-qa"],
        dev: ["portal", "devportal", "qaportal"],
        cataloguing: ["cataloguing", "cataloguing-dev", "cataloguing-qa"],
        ums: ["ums", "ums-dev", "ums-qa"],
        oms: ["oms", "oms-dev", "oms-qa"],
        ims: ["ims", "ims-dev", "ims-qa"],
        lms: ["lms", "lms-dev", "lms-qa"],
        notes: ["notes", "notes-dev", "notes-qa"],
        events: ["events", "events-dev", "events-qa"],
        tag: ["tagging", "tagging-dev", "tagging-qa"],
        ams: ["ams", "ams-dev", "ams-qa"],
        orps: ["orps", "orps-dev", "orps-qa"],
        scheduler: ["scheduler", "scheduler-dev", "scheduler-qa"],
    };
    let retValue = mapping[type][0];

    // if (!IS_PRODUCTION) {
    if (window.location.href.includes("devportal") || window.location.href.includes("192.168.") || window.location.href.includes("//localhost:")) {
        retValue = mapping[type][1];
        if (window.localStorage[type]) return window.localStorage[type];
    } else if (window.location.href.includes("qaportal")) {
        retValue = mapping[type][2];
    }
    // }
    return "https://" + retValue + ".geniemode.com";
};

const _capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

const getOldPortalLink = () => {
    let retValue = "oldportal";
    if (window.location.href.includes("devportal") || window.location.href.includes("localhost") || window.location.href.includes("192.168.") || window.location.href.includes("qaportal")) {
        retValue = "olddevportal";
    }
    return "https://" + retValue + ".geniemode.com";
};

const _mountCancelController = (config) => {
    // add ignore_client_cancellation in front of any api to ignore cancel token
    // backend has ignored this string in every api
    let ignoredApis = [
        "/api/v1/category-attributes",
        "/api/documents/GetDocument",
        "/api/documents/GetOnlyDocument",
        "/api/v1/s3/upload",
        "/api/v1/skus/ll/image",
        "/api/dashboard/GetDashboardOrdersPage",
        "/api/v1/leadCommunication",
        "/api/v1/mail/between-two-stage",
        "/api/v1/notes",
        "/api/v1/NotesUser",
        "/api/v1/event",
        "/api/orders/GetOrder",
        // "/api/v1/resting-place/addresses"
        "/api/v1/brand",
        "/api/gen/usersUnderVp",
        "/api/gen/usersWithRole",
        "/api/gen/usersUnderUserPaginated",
        "/api/gen/usersUnderUser/paginated",
        "/api/orders/getAllCurrency",
        "/api/documents/UploadDocument",
        "/api/v1/lead/reporting/vp/segment",
        "/api/manufacturer/all/dashboard",
        "/api/v1/path/qc",
        "/api/v1/path/qc/updateQcPointDefectUrlByAlias",
        "/api/v2/new/orders/address",
        "/api/v1/path/factory-wip/calendar/steps-group-by-time",
        "/api/v1/leadGenerate/index",
        "/api/v1/product-seasons/get-next-seasons",
        "/api/manufacturer/getAll",
        "/api/manufacturer/auditData",
        "/api/v1/interactions",
        "/api/v1/plan/user/subscription",
        "/api/manufacturer/GetManufacturerDetailsById",
        "/api/v1/path/bank",
        "/api/v1/collection/",
        "/api/v1/plan/user/powers/",
        "/api/v1/techpacks/",
        "/api/v1/path/geniemode-entity",
        // "/api/v1/users", to be done for add product attribution after ignore_client_cancellation
        "/ignore_client_cancellation",
    ];
    ignoredApis = ignoredApis.filter((url) => config?.url && config.url.includes(url));
    return !ignoredApis.length;
};

const _checkIfTenantableUrl = (config) => {
    let retval = false,
        tenantableServices = ["portal", "ums"];
    try {
        if (!!config.url.split("geniemode").length && !!config.url.split("geniemode")[0].split("https://").length && config.url.split("geniemode")[0].split("https://")[1]) {
            let configService = config.url.split("geniemode")[0].split("https://")[1].replace(".", "").replace("-dev", "").replace("-qa", "");
            if (tenantableServices.includes(configService)) {
                retval = true;
            }
            return retval;
        }
    } catch (error) {
        return false;
    }
};

const axiosIntercept = (axios) => {
    window.abortControllers = {};
    let params = new URLSearchParams(window?.location?.search),
        redirectURL = "";
    if (params?.has("redirect_url")) {
        redirectURL = params.get("redirect_url");
    }
    axios.interceptors.request.use(
        (config) => {
            // Do something before request is sent
            if (_checkIfTenantableUrl(config)) {
                config.headers.tenant = _getTenantName();
            }
            // config.headers.Authorization =  token;
            if (document.getElementById("offLine")) {
                if (navigator?.onLine) {
                    document.getElementById("offLine").style.display = "none";
                } else {
                    document.getElementById("offLine").style.display = "block";
                }
            }
            return _mountCancelController(config) ? cancelAbortController(axios, config) : config;
        },
        (error) => {
            // Do something with request error
            return Promise.reject(error);
        },
    );

    axios.interceptors.response.use(
        (response) => {
            removeAbortController(response);
            return response;
        },
        (error) => {
            removeAbortController(error && error.response);
            // if (axios.isCancel(error)) {
            //     console.log('Request cancelled', error); // Can be used later hence leaving this here
            // }
            if (error?.response?.status === 401 && window?.location?.href?.includes("https://portal.geniemode.com")) {
                if (!(error?.response?.config?.url && (error.response.config.url.includes("/api/v1/event") || error.response.config.url.includes("/api/auth/login") || error.response.config.url.includes("/auth/validate") || error.response.config.headers.prevent_unauth_logout))) {
                    window.localStorage.clear();
                    window.location = window.location.origin + "/login?redirect_url=" + (redirectURL ? encodeURIComponent(redirectURL) : encodeURIComponent(window.location.href));
                }
            }
            return Promise.reject(error);
        },
    );
};

const cancelAbortController = (axios, config) => {
    const CancelToken = axios.CancelToken,
        source = CancelToken.source(),
        pathname = config.url.split("?")[0];
    window.abortControllers && window.abortControllers[pathname] && window.abortControllers[pathname].cancel && window.abortControllers[pathname].cancel("Repeat request cancelled");
    config = { ...config, cancelToken: source.token };
    window.abortControllers[pathname] = source;
    return config;
};

const removeAbortController = (response) => {
    response && response.config && window.abortControllers && (window.abortControllers[response.config.url.split("?")[0]] = null);
};

const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(""),
    );
    return JSON.parse(jsonPayload);
};

const getUserData = () => {
    let localStorage = getLsValues(),
        user_data = {};
    user_data = !!localStorage.token ? parseJwt(localStorage.token) : {};
    return user_data;
};

const getGiaData = () => {
    let localStorage = getLsValues(),
        gia_data = {};
    gia_data = !!localStorage.gia ? localStorage.gia : {};
    return gia_data;
};

const _checkKeyValue = (searchTerm, searchArray, key) => {
    if (searchArray && Array.isArray(searchArray) && !!searchArray.length) {
        for (let i = 0; i < searchArray.length; i++) {
            if (!!searchArray[i][key] && searchArray[i][key] === searchTerm) {
                return true;
                break;
            }
        }
        return false;
    } else {
        return false;
    }
};

const getUserPriorityRole = () => {
    let retRole = null,
        localStorage = getLsValues(),
        user_data = {};
    user_data = !!localStorage.token ? parseJwt(localStorage.token) : {};
    let roles = user_data.roles;
    retRole = getRolePriority(roles);
    return retRole;
};

const getRolePriority = (roles) => {
    if (!!roles) {
        let rolesMapper = ["superb_admin", "admin", "finance", "hr", "Gia Sales", "logistics_manager", "logistics_executive", "qc_vp", "qc_manager", "qc", "store_manager", "rm", "client", "manufacturer", "intern", "buyerrisk", "fulfillment", "inventory", "mill", "View_TnC"];
        for (let i = 0; i < rolesMapper.length; i++) {
            if (roles && roles.includes(rolesMapper[i])) {
                return rolesMapper[i];
            }
            for (let j = 0; j < (roles && roles.length ? roles.length : 0); j++) {
                if (roles[j].toLowerCase().includes("testing") && roles[j].toLowerCase().includes(rolesMapper[i])) {
                    return rolesMapper[i];
                }
            }
        }
    }
    return null;
};

const _resizeImageFile = (file, { width, height, quality }) => {
    return new Promise((resolve) => {
        let _URL = window.URL || window.webkitURL,
            img;
        if (file) {
            img = new Image();
            let objectUrl = _URL.createObjectURL(file);
            img.onload = (event) => {
                let widthFinal = width || 200,
                    heightFinal = height || 200,
                    qualityFinal = quality || 100;
                if (event.target.width > widthFinal || event.target.height > heightFinal) {
                    Resizer.imageFileResizer(
                        file,
                        widthFinal,
                        heightFinal,
                        "png",
                        qualityFinal || 100,
                        0,
                        async (uri) => {
                            let url = URL.createObjectURL(uri),
                                newfile;
                            newfile = await fetch(url)
                                .then((r) => r.blob())
                                .then((blobFile) => new File([blobFile], "__" + new Date().getTime() + "__" + uri.name, { type: uri.type }));
                            resolve(newfile);
                        },
                        "file",
                    );
                } else {
                    resolve(file);
                }

                _URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
        }
    });
};

const _getCookieValue = (name) => {
    let nameEQ = name + "=",
        ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c?.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

const _setCookieValue = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const _getTenantName = () => {
    let retVal = "geniemode",
        tenantMap = {
            "portal.iipisourcing.com": "iipisourcing",
            "portal.myfactory.ai": "tsi",
        };

    if (window.location.hostname != "localhost") {
        if (tenantMap && tenantMap[window.location.hostname]) {
            retVal = tenantMap[window.location.hostname];
        }
    } else if (window.location.hostname === "localhost") {
        retVal = "geniemode";
        // write your custom tenant here
    }
    return retVal;
};

const _getConfig = (is_x_token = false) => {
    if (is_x_token) {
        return {
            headers: {
                "x-access-token": !!getLsValues() && !!getLsValues().token ? getLsValues().token.toString() : "",
                // "tenant" : _getTenantName(),
            },
        };
    }
    return {
        headers: {
            authorization: !!getLsValues() && !!getLsValues().token ? "Bearer " + getLsValues().token.toString() : "",
            // "tenant" : _getTenantName(),
        },
    };
};

const _getLogData = (passedThis = null) => {
    let ts = new Date(),
        user_data = getUserData(),
        logData = {
            timestamp: ts.toISOString(),
            epoch: ts.getTime(),
            href: window && window.location && window.location.href,
            baseUrl: window && window.location && window.location.hostname,
            pathname: window && window.location && window.location.pathname,
            search: window && window.location && window.location.search,
            ...user_data,
            priority_role: getUserPriorityRole(),
        };
    if (passedThis && passedThis.dateValue) logData.time_spent = (new Date().getTime() - passedThis.dateValue) / 1000;
    return logData;
};

const _infiniteScrollListener = ({ id = null, passedThis = null, callbackFunc = null, loadMoreID = "loadMore", passedLoadingMore = null, passedHasMore = null }) => {
    if (!!id && !!passedThis && !!callbackFunc && typeof callbackFunc === "function") {
        document.getElementById(id) &&
            document.getElementById(id).addEventListener(
                "scroll",
                _debounce((e) => {
                    _infiniteLoad({ passedThis, callbackFunc, loadMoreID, passedLoadingMore, passedHasMore });
                }, 50),
            );
    }
};

const _infiniteLoad = ({ passedThis = null, callbackFunc = null, loadMoreID = "loadMore", passedLoadingMore = null, passedHasMore = null }) => {
    if (!!passedThis && !!callbackFunc && typeof callbackFunc === "function") {
        var loadMore = document.getElementById(loadMoreID);
        loadMore && _inView(loadMore).partial && _loadMore({ passedThis, callbackFunc, passedLoadingMore, passedHasMore });
        _lazyloadImages();
    }
};

const _loadMore = ({ passedThis = null, callbackFunc = null, passedLoadingMore = null, passedHasMore = null }) => {
    if (!!passedThis && !!callbackFunc && typeof callbackFunc === "function") {
        let { loadingMore, hasMore } = passedThis.state;
        passedLoadingMore && (loadingMore = passedThis["state"][passedLoadingMore]);
        passedHasMore && (hasMore = passedThis["state"][passedHasMore]);
        if (!loadingMore && !!hasMore) {
            passedThis.setState(passedLoadingMore ? { [passedLoadingMore]: true } : { loadingMore: true }, callbackFunc);
        }
    }
};

const _addressLine = (address) => {
    let mapper = ["address_line1", "address_line2", "landmark", "state", "city", "country", "pincode"],
        address_arr = [];
    for (let i = 0; i < mapper.length; i++) {
        address[mapper[i]] && address_arr.push(address[mapper[i]]);
    }
    let address_str = address_arr.join(", ");
    address.id && (address_str = address_str + ` | Address ID: ${address.id}`);
    return address_str;
};

// ver 1.5 is highest
const _buildParams = ({ form, ver = 1.0 }) => {
    if (!!form) {
        let params = {};
        for (let i = 0; i < form.length; i++) {
            for (let j = 0; j < form[i].inputs.length; j++) {
                if (form[i].inputs[j].inputType === "text" || form[i].inputs[j].inputType === "textarea") {
                    params[form[i].inputs[j].paramKey] = !!form[i].inputs[j].value ? form[i].inputs[j].value : null;
                } else if (form[i].inputs[j].inputType === "select" && !form[i].inputs[j].isMulti) {
                    params[form[i].inputs[j].paramKey] = !!form[i].inputs[j].value && !!form[i].inputs[j].value.value ? (typeof form[i].inputs[j].value.value === "string" ? form[i].inputs[j].value.value.split("$$")[0] : form[i].inputs[j].value.value) : null;
                } else if (form[i].inputs[j].inputType === "select" && form[i].inputs[j].isMulti && ver > 1.2) {
                    params[form[i].inputs[j].paramKey] = !!form[i].inputs[j].value ? form[i].inputs[j].value : null;
                } else if (form[i].inputs[j].inputType === "create-select") {
                    if (ver > 1.4 && form[i].inputs[j]?.isMulti) {
                        let values = [];
                        for (let k = 0; k < form[i].inputs[j]?.value?.length; k++) {
                            let newValue = !!form[i].inputs[j]?.value[k]?.value ? (typeof form[i].inputs[j].value[k].value === "string" ? form[i].inputs[j].value[k].value.split("$$")[0] : form[i].inputs[j].value[k].value) : null;
                            !!newValue && values.push(newValue);
                        }
                        params[form[i].inputs[j].paramKey] = values;
                    } else {
                        params[form[i].inputs[j].paramKey] = !!form[i].inputs[j].value && !!form[i].inputs[j].value.value ? (typeof form[i].inputs[j].value.value === "string" ? form[i].inputs[j].value.value.split("$$")[0] : form[i].inputs[j].value.value) : null;
                    }
                } else if (form[i].inputs[j].inputType === "boolean") {
                    params[form[i].inputs[j].paramKey] = !!form[i].inputs[j].value ? form[i].inputs[j].value : false;
                } else if (form[i].inputs[j].inputType === "radio") {
                    params[form[i].inputs[j].paramKey] = !!form[i].inputs[j].value && !!form[i].inputs[j].value?.length ? form[i].inputs[j].value[0]?.value : false;
                } else if (form[i].inputs[j].inputType === "checkbox" || form[i].inputs[j].inputType === "multi-option-select") {
                    let values = [];
                    if (!!form[i].inputs[j].value && Array.isArray(form[i].inputs[j].value) && form[i].inputs[j].value.length > 0) {
                        for (let k = 0; k < form[i].inputs[j].value.length; k++) {
                            values.push(form[i].inputs[j].value[k].value);
                        }
                    }
                    params[form[i].inputs[j].paramKey] = values.length ? values.join(",") : null;
                } else if (form[i].inputs[j].inputType === "file-upload") {
                    let formKey = form[i].inputs[j].formKey || form[i].inputs[j].paramKey;
                    if (ver > 1.3 && form[i].inputs[j].multiple) {
                        params[formKey] = [];
                        for (let k = 0; k < form[i].inputs[j]?.formData.length; k++) {
                            if (form[i].inputs[j].formData[k]?.document_url) {
                                params[formKey].push(form[i].inputs[j].formData[k]?.document_url);
                            }
                        }
                        // params[formKey] = form[i].inputs[j].sendOnlyUrl ? form[i].inputs[j].value : form[i].inputs[j].file;
                    } else {
                        params[formKey] = form[i].inputs[j].sendOnlyUrl ? form[i].inputs[j].value : form[i].inputs[j].file;
                    }
                } else if (form[i].inputs[j].inputType === "date-picker" && ver > 1.0) {
                    params[form[i].inputs[j].paramKey] = form[i].inputs[j].value && typeof form[i].inputs[j].value === "object" ? new Date(form[i].inputs[j].value).getTime() : "";
                }
            }
        }
        return params;
    }
};

const _timedPopup = ({ passedThis = null, callbackFunc = null, display = true, message = "", error = false, time = 3000 }) => {
    if (!!passedThis) {
        let { popup } = passedThis.state;
        popup.timer && clearTimeout(popup.timer);
        popup.timer = setTimeout(() => passedThis.setState({ popup: { ...popup, display: false } }), time);
        popup = { ...popup, display, message, error };
        passedThis.setState({ popup }, callbackFunc);
    }
};

const _closeImageCropper = ({ passedThis = null }) => {
    if (!!passedThis) {
        let imageCropperStack = [...passedThis.state.imageCropperStack]; // remove the imageCropper from stack
        imageCropperStack.splice(0, 1);
        passedThis.setState({ imageCropperStack });
    }
};

const _zi = ({ passedThis = null, e = null, url = null, targetIdx = null, passedTarget = null }) => {
    url += "?format=webp";
    if (!!passedThis) {
        let large = passedThis.large.current,
            target = passedTarget ? passedThis[passedTarget][targetIdx].current : passedThis.targets[targetIdx].current,
            native_width,
            native_height;
        if (!target.hasAttribute("an-zm")) {
            var backgroundImage = "url(" + url + ")";
            var image_object = new Image();
            image_object.onload = function () {
                if (!target.hasAttribute("an-zm")) {
                    native_width = image_object.width;
                    native_height = image_object.height;
                    if (native_width && native_height && (parseInt(native_width) > 1000 || parseInt(native_height) > 1000)) {
                        let a = Math.max(native_width, native_height),
                            b = a / 1000,
                            c = Math.max(10, 100 / b);
                        native_width = parseInt((native_width * c) / 100);
                        native_height = parseInt((native_height * c) / 100);
                    }
                    target.setAttribute("an-zm", backgroundImage);
                    target.setAttribute("an-bgSize", native_width + "px " + native_height + "px");
                    target.setAttribute("an-wi", " " + native_width);
                    target.setAttribute("an-hi", " " + native_height);
                }
            };
            image_object.src = url;
        }
        large.style.backgroundColor = "#fff";
        large.style.backgroundImage = "url(" + url + ")";
        large.style.backgroundRepeat = "no-repeat";
        large.style.display = "block";
        let nativeWidth = native_width || target.getAttribute("an-wi"),
            nativeHeight = native_height || target.getAttribute("an-hi"),
            nativeBgSize = native_width && native_height ? native_width + "px " + native_height + "px" : target.getAttribute("an-bgSize");
        large.style.backgroundSize = nativeBgSize;
        let magnify_offset = target.getBoundingClientRect(),
            mx = e.clientX - magnify_offset.left,
            my = e.clientY - magnify_offset.top;
        let rx = Math.round((mx / magnify_offset.width) * nativeWidth - 150) * -1,
            ry = Math.round((my / magnify_offset.height) * nativeHeight - 150) * -1;
        let bgp = rx + "px " + ry + "px";
        large.style.backgroundPosition = bgp;
        let adjust = { x: 48, y: -150 };
        large.style.left = e.clientX + adjust.x + "px";
        large.style.top = e.clientY + adjust.y + "px";
    }
};

const _zo = ({ passedThis = null, e }) => {
    if (!!passedThis) {
        passedThis.large.current.style.display = "none";
    }
    if (!!e && new Date().getTime() - e.target.zoom_enter_time > 500) {
        passedThis.logZoomEvent((new Date().getTime() - e.target.zoom_enter_time) / 1000);
    }
};

const _isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

const _nestedJSONStringToJSON = (obj) => {
    try {
        if (obj && typeof obj === "string" && _isJsonString(obj)) {
            obj = JSON.parse(obj);
        }
        if (obj && typeof obj === "object") {
            let keys = Object.keys(obj);
            for (let i = 0; i < keys.length; i++) {
                obj[keys[i]] = _nestedJSONStringToJSON(obj[keys[i]]);
            }
        }
        return obj;
    } catch (error) {
        console.error(error);
        return obj;
    }
};

const _paginationCall = ({ formName, id, passedThis }) => {
    let { [formName]: form } = passedThis.state,
        { divIdx, inpIdx } = _findIdxById(id, form);
    if (!(divIdx === null || inpIdx === null)) {
        if (form[divIdx].inputs[inpIdx]?.pagination?.hasMore) {
            form[divIdx].inputs[inpIdx].css = form[divIdx].inputs[inpIdx].css + " paginated-select";
            passedThis.setState({ [formName]: form });
            let pagination = form[divIdx].inputs[inpIdx].pagination;
            form[divIdx].inputs[inpIdx]?.pagination?.func &&
                typeof form[divIdx].inputs[inpIdx].pagination.func === "function" &&
                form[divIdx].inputs[inpIdx].pagination.func({
                    divIdx,
                    inpIdx,
                    page: pagination?.page,
                    size: pagination?.size,
                    searchTerm: pagination?.search,
                    prefill: false,
                });
        }
    }
};

function replacer(string) {
    if (string && !(string.includes("<!doctype ") || string.includes("<style>") || string.includes("<meta") || string.includes("<body") || string.includes("<div"))) {
        const urlRegex = /<?https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)>?/gm,
            matches = string.match(urlRegex);
        if (matches) {
            for (let i = 0; i < matches.length; i++) {
                let url = matches[i];
                if (matches[i].charAt(0) === "<") url = url.substring(1, url.length - 1);
                let startingIdx = string.indexOf(matches[i]);
                if (startingIdx >= 5 && string.slice(startingIdx - 5, startingIdx) === "href=") {
                    continue;
                }
                string = string.replace(matches[i], ` <a href=${url} target="_blank">${url}</a> `);
            }
        }
    }
    return string;
}

const _renderLeadTemplate = (html = "", variables = {}, regex) => {
    if (html && typeof html === "string") {
        let variableMatches = html.match(regex);
        html = replacer(html);
        if (!!variableMatches && Array.isArray(variableMatches) && variableMatches.length > 0) {
            for (let i = 0; i < variableMatches.length; i++) {
                let variable = variableMatches[i].replace("{{", "").replace("}}", "");
                let parsedvariable = parseInt(variable);
                if (!!variables[parsedvariable]) {
                    html = html.replace(variableMatches[i], `${variables[parsedvariable]}`);
                } else {
                    html = html.replace(
                        variableMatches[i],
                        `<b 
								style='
								color: #fff;
								background: #000;
								padding: 4px 10px;
								'
							>
								<i>Variable ${variable}</i>
							</b>`,
                    );
                }
            }
        }
        return { __html: html };
    }
    return null;
};

const _positiveNumCondenser = (num) => {
    if (num <= 0) {
        return 0;
    } else if (num <= 999) {
        return num;
    } else if (num > 999 && num <= 99999) {
        return (num / 1000).toFixed(1) + "K";
    } else if (num > 99999 && num <= 999999) {
        return parseInt(num / 1000) + "K";
    } else if (num > 999999 && num <= 99999999) {
        return (num / 1000000).toFixed(1) + "M";
    } else if (num > 99999999 && num <= 999999999) {
        return parseInt(num / 1000000) + "M";
    } else {
        return "1B+";
    }
};

const _positiveNumCondenserMil = (labelValue) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
        ? parseFloat((Math.sign(Number(labelValue)) * (Math.abs(Number(labelValue)) / 1.0e9)).toFixed(3)) + "B"
        : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
        ? parseFloat((Math.sign(Number(labelValue)) * (Math.abs(Number(labelValue)) / 1.0e6)).toFixed(3)) + "M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
        ? parseFloat((Math.sign(Number(labelValue)) * (Math.abs(Number(labelValue)) / 1.0e3)).toFixed(3)) + "K"
        : parseFloat((Math.sign(Number(labelValue)) * Math.abs(Number(labelValue))).toFixed(3));
};

const _makeStateObject = (data, rejectKeys) => {
    let univarsallyRejectedKeys = ["dateValue"];
    data = data;
    if (!!rejectKeys && Array.isArray(rejectKeys)) {
        let ObjKeys = Object.keys(data);
        for (let i = 0; i < ObjKeys.length; i++) {
            if (rejectKeys.includes(ObjKeys[i]) || univarsallyRejectedKeys.includes(ObjKeys[i])) delete data[ObjKeys[i]];
        }
    }
    return data;
};

const _maintainBackState = ({ key, scrollId, scrollTop, data }) => {
    if (!!key && !!scrollId && !!data) {
        if (window.geniHistory && typeof window.geniHistory === "object") {
            window.geniHistory[key] = { data, ["scroll"]: scrollTop };
        } else {
            window.geniHistory = {
                [key]: { data, ["scroll"]: scrollTop },
            };
        }
    } else {
        setTimeout(() => {
            window?.location?.reload();
        }, 10);
        console.error("_maintainBackState could not find necessary params");
    }
};

const _preserveBackState = ({ key, scrollId, scrollTop, cancelApiPath, passedThis, rejectKeys, scrollRef, callbackFunction }) => {
    if (!!key && !!scrollId && window.geniHistory && window.geniHistory[key] && window.geniHistory[key].data) {
        passedThis.setState({ ..._makeStateObject(window.geniHistory[key].data, rejectKeys) }, () => {
            setTimeout(() => {
                window.abortControllers && window.abortControllers[cancelApiPath] && window.abortControllers[cancelApiPath].cancel && window.abortControllers[cancelApiPath].cancel("Preserving Back State");
                if (scrollId) !!document.getElementById(scrollId) && document.getElementById(scrollId).scrollTo({ top: window.geniHistory[key]["scroll"], left: 0, behavior: "auto" });
                if (scrollRef) passedThis[scrollRef].current.scrollTo({ top: window.geniHistory[key]["scroll"], left: 0, behavior: "auto" });
                setTimeout(() => {
                    passedThis.setState({ hasMore: true, error: false }, () => {
                        callbackFunction && callbackFunction();
                    });
                });
            }, 0);
        });
        return true;
    } else {
        setTimeout(() => {
            window?.location?.reload();
        }, 10);

        return false;
    }
};

const _pageExitTime = ({ passedThis = null }, page) => {
    let initialTime = passedThis && passedThis.state && passedThis.state.dateValue && passedThis.state.dateValue ? passedThis.state.dateValue : 0,
        date = new Date().getTime(),
        totalTime = (date - initialTime) / 1000 + " Sec";
    _universal_logger(page + "Exit", { "Leaving Epoch": date, TimeSpent: totalTime, "Initial Time Epoch": initialTime, IOSapp: !!localStorage.getItem("inApp"), AndroidApp: !!localStorage.getItem("inAnroidApp") });
};

const _pageEnterTime = ({ passedThis = null }, page) => {
    let date = new Date().getTime();
    passedThis.setState({
        dateValue: date,
    });
    if (!passedThis || page === "" || !page) {
        return false;
    }
    // window.webengage && window.webengage.track && window.webengage.track( page ,{"Initial Time Epoch":date});

    _universal_logger(page, { "Initial Time Epoch": date, IOSapp: !!localStorage.getItem("inApp"), AndroidApp: !!localStorage.getItem("inAnroidApp") });
};

const _sanitizeWEevent = (obj = null) => {
    try {
        if (obj && typeof obj === "object") {
            let keys = Object.keys(obj);
            for (let i = 0; i < keys.length; i++) {
                if (obj[keys[i]] === null || obj[keys[i]] === undefined || (typeof obj[keys[i]] === "string" && obj[keys[i]].trim() === "")) {
                    obj[keys[i]] = "";
                }
                if (typeof obj[keys[i]] === "object") {
                    // obj[keys[i]] = {"object_in_object":"true"};
                    // console.log(keys[i],"obj[keys[i]]",obj[keys[i]],"keys[i]");
                    obj[keys[i]] = _sanitizeWEevent(obj[keys[i]]);
                }
            }
            return obj;
        } else {
            return obj;
        }
    } catch (error) {
        console.error(error);
        return obj;
    }
};

const _universal_logger = (event_name, event_params, logFirebase = true, logWE = true) => {
    if (typeof event_name == "string" && typeof event_name != "undefined" && typeof event_params === "object") {
        if (getAnalytics && logFirebase) {
            let userProperties = { ..._getLogData() },
                eventDataToBeLogged = { ...event_params, ...userProperties };
            try {
                const analytics = getAnalytics();
                !!analytics && logEvent(analytics, event_name, eventDataToBeLogged);
                !!analytics && !!setUserProperties && setUserProperties(analytics, { ...userProperties });
            } catch (error) {
                if (error?.message?.includes("Need to provide options")) {
                    window.logEventAfterInitialization = {
                        event_name,
                        eventDataToBeLogged,
                        userProperties,
                    };
                } else {
                    if (!(window.location.href.includes("devportal") || window.location.href.includes("192.168.") || window.location.href.includes("//localhost:"))) {
                        console.log("Error While Logging UniversalEvent", error);
                    }
                }
            }
        }
        if (logWE && window.webengage) {
            window.webengage.track(event_name, event_params);
        }
    } else return false;
};

const _getOrSetIpLocation = () => {
    if (_existAndGetLsValue("ipLocation") && _existAndGetLsValue("ipLocation").ipLocation) {
        return _isJsonString(_existAndGetLsValue("ipLocation").ipLocation) ? JSON.parse(_existAndGetLsValue("ipLocation").ipLocation) : _existAndGetLsValue("ipLocation").ipLocation;
    } else {
        axios
            .get("https://api.db-ip.com/v2/free/self")
            .then((response) => {
                let geoLocation = JSON.stringify(response.data);
                _setLsValues({ ipLocation: geoLocation });
            })
            .catch((error) => {
                console.error(error);
            });
        return {};
    }
};

/**
 *
 * @Event logger function
 *  _universal_logger_v2({
        eventName: "",
        entity_name: "",
        entity_id: "",
        page: "",
        type: "",
        element: "",
        scroll_depth: "",
        passedThis: this,
        businessDataObj: {}
    });
 */

const _universal_logger_v2 = async ({ eventName, entity_name, entity_id, page, type, element, error, sub_entity_name, sub_entity_id, source = "", scroll_depth = "", logFirebase = true, logWE = true, logBackend = true, userId = null, userName = null, phone = null, businessDataObj = {}, entityTypeObj = {}, triggerInfoObj = {}, deviceInfoObj = {}, sourceObj = {}, send_to_we = "true", passedThis }) => {
    try {
        // window.time_spent = {
        //     page_timer_map: {},
        //     last_page: "",
        //     inactivity_time: 0,
        //     tab_start_time: 0,
        //     tab_end_time: 0,
        //     tab_id: _generateUUID(),
        //     tab_focus_count: 0
        // };
        if (!eventName) throw new Error("Required field not passed");
        if (!type) type = eventName;
        let ts = new Date(),
            time_spent = 0,
            userData = getUserData && getUserData(),
            userPriorityRole = getUserPriorityRole && getUserPriorityRole(),
            ipLocation = _getOrSetIpLocation && _getOrSetIpLocation();

        // tab start
        if (eventName === "tab_start" && window?.time_spent?.last_page) {
            window.time_spent.page_timer_map[window.time_spent.last_page] = ts.getTime();
            window.time_spent.tab_start_time = ts.getTime();
            if (window?.time_spent?.last_page && window?.time_spent?.tab_end_time) {
                window.time_spent.inactivity_time = (ts.getTime() - window?.time_spent?.tab_end_time) / 1000;
                window.time_spent.tab_end_time = 0;
                window.time_spent.tab_time_spent = 0;
            }
            window.time_spent.tab_focus_count += 1;
            _universal_logger_v2({ eventName: "view", page: window?.time_spent?.last_page });
        }

        // tab end
        if (eventName === "tab_end") {
            if (window?.time_spent?.page_timer_map) {
                let keys = Object.keys(window.time_spent.page_timer_map);
                for (let i = 0; i < keys.length; i++) {
                    _universal_logger_v2({ eventName: "leave", page: keys[i] });
                    // console.log("leave to be called for page ->", keys[i]);
                }
            }
            window.time_spent.tab_end_time = ts.getTime();
            window.time_spent.tab_time_spent = window?.time_spent?.tab_end_time && window?.time_spent?.tab_start_time && window?.time_spent?.tab_end_time > window?.time_spent?.tab_start_time ? (window?.time_spent?.tab_end_time - window?.time_spent?.tab_start_time) / 1000 : 0;
        }

        // view page
        if (eventName === "view") {
            window.time_spent = {
                ...window.time_spent,
                page_timer_map: {
                    ...window.time_spent.page_timer_map,
                    [page]: ts.getTime(),
                },
                last_page: page,
            };
        } else if (page) {
            time_spent = window?.time_spent?.page_timer_map?.[page] ? (ts.getTime() - window?.time_spent?.page_timer_map?.[page]) / 1000 : 0;
        }

        if (element === "zoom") {
        }

        // leave page
        if (eventName === "leave") {
            if (window?.time_spent?.page_timer_map && window.time_spent.page_timer_map.hasOwnProperty(page)) {
                delete window.time_spent.page_timer_map[page];
            }
        }

        let query_params = new URLSearchParams(window?.location?.search),
            query_params_obj = {};
        query_params.forEach((k, v) => {
            query_params_obj[v] = k;
        });

        let event_data = {
            userId: userData?.userId ? userData?.userId : userId ? userId : _getCookieValue("_ga") ? _getCookieValue("_ga") : new Date().getTime(),
            userName: userData?.username ? userData?.username : userName ? userName : _getCookieValue("_ga") ? _getCookieValue("_ga") : new Date().getTime(),
            phone,
            eventName,
            businessDataObj: {
                time_spent,
                ...businessDataObj,
                _ga: _getCookieValue("_ga"),
            },
            entityTypeObj: {
                priority_role: userPriorityRole,
                ...userData,
                ...entityTypeObj,
            },
            triggerInfoObj: {
                platform: "frontend",
                repo: "genieReactFe",
                Class: "NA",
                function: "NA",
                page,
                type,
                entity_name,
                entity_id,
                sub_entity_name,
                sub_entity_id,
                element,
                error,
                source,
                inactivity_time: window?.time_spent?.inactivity_time,
                tab_id: window?.time_spent?.tab_id,
                tab_start_time: window?.time_spent?.tab_start_time,
                tab_time_spent: window?.time_spent?.tab_time_spent,
                tab_end_time: window?.time_spent?.tab_end_time,
                tab_focus_count: window?.time_spent?.tab_focus_count,
                _ga: _getCookieValue("_ga"),
                ...triggerInfoObj,
            },
            deviceInfoObj: {
                userAgent: navigator && navigator?.userAgent,
                appName: navigator && navigator?.appName,
                platform: navigator && navigator?.platform,
                vendor: navigator && navigator?.vendor,
                NetworkInformation: navigator && navigator?.connection,
                userAgentData: navigator && navigator?.userAgentData,
                language: navigator && navigator?.language,
                allLanguage: navigator && navigator?.languages,
                deviceMemory: navigator && navigator?.deviceMemory,
                maxTouchPoints: navigator && navigator?.maxTouchPoints,
                online: navigator && navigator?.onLine,
                location: _getOrSetIpLocation(),
                ios_app: !!localStorage && !!localStorage.getItem("inApp"),
                android_app: !!localStorage && !!localStorage.getItem("inAnroidApp"),
                ...deviceInfoObj,
            },
            sourceObj: {
                product: "portal",
                timestamp: ts.toISOString(),
                epoch: ts.getTime(),
                href: window && window.location && window?.location?.href,
                baseUrl: window && window.location && window?.location?.hostname,
                pathname: window && window.location && window?.location?.pathname,
                search: window && window.location && window?.location?.search,
                query_params: query_params_obj,
                _ga: _getCookieValue("_ga"),
                ...sourceObj,
            },
        };
        let ga_event_data = {
            ...businessDataObj,
            userId: userData?.userId ? userData?.userId : userId ? userId : _getCookieValue("_ga") ? _getCookieValue("_ga") : new Date().getTime(),
            userName: userData?.username ? userData?.username : userName ? userName : _getCookieValue("_ga") ? _getCookieValue("_ga") : new Date().getTime(),
            phone,
            eventName,
            page,
            type,
            element,
            source,
            first_name: userData?.firstName ? userData?.firstName : "",
            last_name: userData?.lastName ? userData?.lastName : "",
            roles: userData?.roles && Array.isArray(userData?.roles) && userData?.roles.length > 0 ? userData?.roles.sort().join(";") : "",
            groups: userData?.groups && Array.isArray(userData?.groups) && userData?.groups.length > 0 ? userData?.groups.sort().join(";") : "",
            piority_role: userPriorityRole,
            merchandiser: userData?.roles ? userData?.roles.includes("rm") || userData?.roles.includes("ops_rm") : "",
            buyer: userData?.roles ? userData?.roles.includes("buyer") : "",
            factory: userData?.roles ? userData?.roles.includes("manufacturer") : "",
            admin: userData?.roles ? userData?.roles.includes("admin") : "",
            designer: userData?.roles ? userData?.roles.includes("designer") : "",
            vp: userData?.roles ? userData?.roles.includes("vp") : "",
            avp: userData?.roles ? userData?.roles?.includes("avp") : "",
            visit_time: window?.time_spent?.page_timer_map && page ? window?.time_spent?.page_timer_map?.[page] : "NA",
            leave_time: ts ? ts.getTime() : "NA",
            timestamp: ts.toISOString(),
            epoch: ts.getTime(),
            time_spent,
            scroll_depth,
            href: window && window.location && window?.location?.href,
            baseUrl: window && window.location && window?.location?.hostname,
            pathname: window && window.location && window?.location?.pathname,
            search: window && window.location && window?.location?.search,
            location_ip: ipLocation?.ipAddress,
            location_city: ipLocation?.city,
            location_state: ipLocation?.stateProv,
            location_country: ipLocation?.countryName,
            location_continent: ipLocation?.continentName,
            userAgent: navigator && navigator?.userAgent,
            network_downlink: navigator && navigator?.connection?.downlink,
            network_info: navigator && navigator?.connection?.effectiveType,
            language: navigator && navigator?.language,
            _ga: _getCookieValue("_ga"),
            entity_name,
            entity_id,
            ios_app: !!localStorage && !!localStorage.getItem("inApp"),
            android_app: !!localStorage && !!localStorage.getItem("inAnroidApp"),
            inactivity_time: window?.time_spent?.inactivity_time,
            tab_id: window?.time_spent?.tab_id,
            tab_start_time: window?.time_spent?.tab_start_time,
            tab_time_spent: window?.time_spent?.tab_time_spent,
            tab_end_time: window?.time_spent?.tab_end_time,
            tab_focus_count: window?.time_spent?.tab_focus_count,
        };
        event_data = _sanitizeWEevent(event_data);
        ga_event_data = _sanitizeWEevent(ga_event_data);
        if (logBackend) {
            let response = await axios.post(
                `${getBaseUrl("events")}/api/v1/event`,
                {
                    event: event_data,
                    username: "frontend",
                    password: "geniemode@123456",
                    send_to_we,
                },
                _getConfig(),
            );
            // console.log("logging backend event ");
        }
        if (window.firebaseAnalytics && logFirebase) {
            logEvent(window.firebaseAnalytics, eventName, ga_event_data);
            console.log("logging firebase event ");
        }
        if (window.webengage && logWE) {
            window.webengage.track(eventName, _sanitizeWEevent(event_data));
            // console.log("logging webengage event ", _sanitizeWEevent(event_data));
        }
        // console.log("window.time_spent --> ", window.time_spent, JSON.stringify(window.time_spent.page_timer_map), window.time_spent.tab_time_spent);
    } catch (error) {
        if (error?.message !== "Request aborted") {
            console.error("Unable to log data >>> ", error);
        }
    }
};

const _logBackendEvent = async ({ userId = null, userName = null, phone = null, eventName = null, businessDataObj = {}, entityTypeObj = {}, triggerInfoObj = {}, deviceInfoObj = {}, sourceObj = {}, send_to_we = "false", passedThis }) => {
    try {
        if (!eventName) throw new Error("Required field not passed");
        let ts = new Date(),
            time_spent = "NA";
        if (passedThis && passedThis.state && passedThis.state.dateValue) time_spent = (new Date().getTime() - passedThis.state.dateValue) / 1000;
        let event_data = {
            userId: getUserData && getUserData() && getUserData().userId ? getUserData().userId : userId ? userId : _getCookieValue("_ga") ? _getCookieValue("_ga") : new Date().getTime(),
            userName: getUserData && getUserData() && getUserData().username ? getUserData().username : userName ? userName : _getCookieValue("_ga") ? _getCookieValue("_ga") : new Date().getTime(),
            phone,
            eventName,
            businessDataObj: {
                time_spent,
                ...businessDataObj,
            },
            entityTypeObj: {
                priority_role: getUserPriorityRole(),
                ...entityTypeObj,
            },
            triggerInfoObj: {
                type: "frontend",
                repo: "genieReactFe",
                Class: "NA",
                function: "NA",
                ...triggerInfoObj,
            },
            deviceInfoObj: {
                userAgent: navigator.userAgent,
                appName: navigator.appName,
                platform: navigator.platform,
                vendor: navigator.vendor,
                NetworkInformation: navigator.connection,
                userAgentData: navigator.userAgentData,
                language: navigator.language,
                allLanguage: navigator.languages,
                deviceMemory: navigator.deviceMemory,
                maxTouchPoints: navigator.maxTouchPoints,
                online: navigator.onLine,
                location: _getOrSetIpLocation(),
                ...deviceInfoObj,
            },
            sourceObj: {
                product: "portal",
                timestamp: ts.toISOString(),
                epoch: ts.getTime(),
                time_spent,
                href: window && window.location && window.location.href,
                baseUrl: window && window.location && window.location.hostname,
                pathname: window && window.location && window.location.pathname,
                search: window && window.location && window.location.search,
                ...sourceObj,
            },
        };
        event_data = _sanitizeWEevent(event_data);
        let response = await axios.post(
            `${getBaseUrl("events")}/api/v1/event`,
            {
                event: event_data,
                username: "frontend",
                password: "geniemode@123456",
                send_to_we,
            },
            _getConfig(),
        );
    } catch (error) {
        console.error("Unable to log data to backend >>> ", error);
    }
};

const _price_in_words = (price) => {
    if (!isNaN(price)) {
        var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
            dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
            tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
            handle_tens = function (dgt, prevDgt) {
                return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
            },
            handle_utlc = function (dgt, nxtDgt, denom) {
                return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "");
            };

        var str = "",
            digitIdx = 0,
            digit = 0,
            nxtDigit = 0,
            words = [];
        if (((price += ""), isNaN(parseInt(price)))) str = "";
        else if (parseInt(price) > 0 && price.length <= 10) {
            for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
                switch (((digit = price[digitIdx] - 0), (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0), price.length - digitIdx - 1)) {
                    case 0:
                        words.push(handle_utlc(digit, nxtDigit, ""));
                        break;
                    case 1:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 2:
                        words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
                        break;
                    case 3:
                        words.push(handle_utlc(digit, nxtDigit, "Thousand"));
                        break;
                    case 4:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 5:
                        words.push(handle_utlc(digit, nxtDigit, "Lakh"));
                        break;
                    case 6:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 7:
                        words.push(handle_utlc(digit, nxtDigit, "Crore"));
                        break;
                    case 8:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 9:
                        words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? " and" : " Crore") : "");
                        break;
                    default:
                        break;
                }
            str = words.reverse().join("");
        } else str = "";
        return str;
    }
};

const _checkForPending = ({ type = null, passedThis = null }) => {
    if (type) {
        let pending_reqs = _existAndGetLsValue(`PENDING_${type}`)[`PENDING_${type}`],
            pending = _isJsonString(pending_reqs) ? _nestedJSONStringToJSON(pending_reqs) : [];
        passedThis && passedThis.setState({ pending });
        return pending;
    }
    return [];
};

const _removeFromLSwithUniqueID = (type, id) => {
    return new Promise(async (resolve, reject) => {
        if (type && id) {
            let pending_list = _checkForPending({ type });
            for (let i = 0; i < pending_list.length; i++) {
                let pending = pending_list[i];
                if (pending.jsonVal[pending.uniqueID] === id) {
                    pending_list.splice(i, 1);
                }
            }
            _setLsValues({ [`PENDING_${type}`]: JSON.stringify(pending_list) });
            resolve(pending_list);
        } else {
            reject("type or id not given");
        }
    });
};

const _axiosPendingReqs = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (!data) reject("data is not present");
            let response = null;
            if (data.method === "GET" || data.method === "DELETE") {
                response = await axios[data.method.toLowerCase()](data.url, { ..._getConfig(), params: data.jsonVal });
            } else {
                let payload = data.jsonVal;
                if (data?.type?.payload === "formdata") payload = buildFormData(payload);
                response = await axios[data.method.toLowerCase()](data.url, payload, _getConfig());
            }
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

const _matchUniqueIDs = (obj1, obj2, uniqueIDs) => {
    uniqueIDs = uniqueIDs.split(",");
    for (let i = 0; i < uniqueIDs.length; i++) {
        if (obj1[uniqueIDs[i]] !== obj2[uniqueIDs[i]]) return false;
    }
    return true;
};

const _setPendingReqToIDB = async (message) => {
    // event is a MessageEvent object
    // let message = JSON.parse(event?.data); // latest message received to client
    if (message.action === "CLONE_REQ_TO_LOCAL") {
        // check if it is a pending offline request

        let key = message?.type?.page,
            dbValues = await idbPendingReqs.get(key);

        let obj_to_set = { [key]: [] };
        if (!!dbValues && Array.isArray(dbValues) && !!dbValues.length) {
            for (let i = 0; i < dbValues.length; i++) {
                let uniqueIDs = dbValues[i].uniqueID,
                    jsonVal = dbValues[i].jsonVal;
                // if localstorage pending reqs has different unique id request then push
                if (!_matchUniqueIDs(jsonVal, message.jsonVal, uniqueIDs)) {
                    obj_to_set[key].push(dbValues[i]);
                }
            }
        }
        // else push to IDB
        obj_to_set[key].push(message);
        // set to IDB
        await idbPendingReqs.set(key, obj_to_set[key]);
    }
};

const _removePendingReqFromIDB = (key, uniqueID, uniqueIDValue) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (!key) throw new Error("key not valid");
            let dbValues = await idbPendingReqs.get(key);

            let obj_to_set = { [key]: [] };
            if (!!dbValues && Array.isArray(dbValues) && !!dbValues.length) {
                for (let i = 0; i < dbValues.length; i++) {
                    let uniqueIDs = dbValues[i].uniqueID,
                        jsonVal = dbValues[i].jsonVal;
                    // if localstorage pending reqs has same unique id request
                    if (!_matchUniqueIDs(jsonVal, { [uniqueID]: uniqueIDValue }, uniqueIDs)) {
                        obj_to_set[key].push(dbValues[i]);
                    }
                }
            }
            // set to IDB
            await idbPendingReqs.set(key, obj_to_set[key]);
            resolve(obj_to_set[key]);
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
};

const _removeKeyFromIDB = (key) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (!key) throw new Error("key not valid");
            let result = await idbPendingReqs.delete(key);
            resolve(result);
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
};

const _incRetryCountPendingReqinIDB = (key, uniqueID, uniqueIDValue) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (!key) throw new Error("key not valid");
            let dbValues = await idbPendingReqs.get(key);
            let obj_to_set = { [key]: [] };
            if (!!dbValues && Array.isArray(dbValues) && !!dbValues.length) {
                for (let i = 0; i < dbValues.length; i++) {
                    let uniqueIDs = dbValues[i].uniqueID,
                        jsonVal = dbValues[i].jsonVal,
                        retryCount = dbValues[i].retryCount;
                    // if IDB pending reqs has same unique id request
                    if (_matchUniqueIDs(jsonVal, { [uniqueID]: uniqueIDValue }, uniqueIDs)) {
                        dbValues[i].retryCount += 1;
                    }
                    obj_to_set[key].push(dbValues[i]);
                }
            }
            // set to IDB
            await idbPendingReqs.set(key, obj_to_set[key]);
            resolve(obj_to_set[key]);
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
};

const _qcAllRights = () => {
    return getUserData()?.roles?.includes("qc_manager") || getUserData()?.roles?.includes("qc_vp") || getUserPriorityRole() === "admin";
};

const _fuzzySearch = async (search, list, keys, custom_options = {}) => {
    try {
        if (!search) throw new Error("Search term not provided");
        if (!list || !Array.isArray(list)) throw new Error("List not provided");
        if (!keys || !Array.isArray(keys)) throw new Error("Keys not provided");
        const options = {
            // isCaseSensitive: false,
            // includeScore: false,
            // shouldSort: true,
            // includeMatches: false,
            // findAllMatches: false,
            // minMatchCharLength: 1,
            // location: 0,
            // threshold: 0.6,
            // distance: 100,
            // useExtendedSearch: false,
            // ignoreLocation: false,
            // ignoreFieldNorm: false,
            // fieldNormWeight: 1,
            keys,
            ...custom_options,
        };
        const fuse = new Fuse(list, options);
        return new Promise((resolve) => resolve(fuse.search(search).map((v) => v.item)));
    } catch (error) {
        console.error(error);
        return new Promise((resolve, reject) => reject(error));
    }
};

const _b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

const _generateUUID = () => uuidv4(); // univerally unique identifier

const _sanitizeObject = (obj) => {
    let keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i],
            value = obj[key];
        if (value === null || (Array.isArray(value) && value.length === 0) || (typeof value === "object" && !Array.isArray(value) && Object.keys(value).length === 0) || value === undefined || value === "") {
            delete obj[key];
        }
    }
    return obj;
};

const _makeFloat = (val) => {
    if (val === null || val === undefined) return 0;
    else if (!isNaN(val)) return parseFloat(val);
    else return 0;
};

const _getFinancialYear = () => {
    let today = new Date(),
        fiscalyear = {
            begin: today.getFullYear(),
            end: today.getFullYear() + 1,
            string: today.getFullYear() + "-" + (today.getFullYear() + 1),
        };
    if (today.getMonth() + 1 <= 3) {
        fiscalyear = {
            begin: today.getFullYear() - 1,
            end: today.getFullYear(),
            string: today.getFullYear() - 1 + "-" + today.getFullYear(),
        };
    }
    return fiscalyear;
};

const _computeDaysFromTS = (from_epoch) => {
    if (!from_epoch) return "---";
    from_epoch = new Date(from_epoch).getTime();
    let today = new Date().getTime(),
        diffMiliSecs = today - from_epoch;
    return parseInt(diffMiliSecs / (1000 * 60 * 60 * 24));
};

const _isNumber = (num) => {
    if (num === null || num === "" || num === undefined || num === "Infinity") return false;
    return !isNaN(num);
};

const _promisedSetState = (passedThis, state) =>
    new Promise((resolve, reject) => {
        try {
            passedThis.setState(state, () => resolve(true));
        } catch (error) {
            reject(false);
        }
    });

const __slashNsplitter = (text) => {
    let elem = <></>;
    if (!!text && typeof text === "string") {
        elem = (
            <>
                {text.split("\n").map((str) => (
                    <div style={{ height: !str ? "10px" : "auto" }}>{str}</div>
                ))}
            </>
        );
    }
    return elem;
};

const _inWords = (num) => {
    let a = ["", "one ", "two ", "three ", "four ", "five ", "six ", "seven ", "eight ", "nine ", "ten ", "eleven ", "twelve ", "thirteen ", "fourteen ", "fifteen ", "sixteen ", "seventeen ", "eighteen ", "nineteen "];
    let b = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
    if ((num = num.toString()).length > 9) return num;
    let n = ("000000000" + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    let str = "";
    str += n[1] != 0 ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore " : "";
    str += n[2] != 0 ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh " : "";
    str += n[3] != 0 ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand " : "";
    str += n[4] != 0 ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred " : "";
    str += n[5] != 0 ? (str != "" ? "& " : "") + (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) : "";
    str += "only ";
    return str;
};

const _withDecimal = (n) => {
    var nums = n.toString().split(".");
    var whole = _inWords(nums[0]);
    if (nums.length == 2) {
        var fraction = _inWords(nums[1]);
        return whole.replace("only", "") + " point " + fraction;
    } else {
        return whole;
    }
};

const _getEntityName = (val) => {
    val = val?.value ? val.value : val;
    if (typeof val != "string") return val;
    let mapper = [
        ["geniemode india", "Geniemode Global Pvt Ltd"],
        ["geniemode us", "Geniemode Global INC"],
        ["geniemode uk", "Geniemode UK Ltd"],
        ["iipi global llp faridabad", "IIPI Global LLP"],
        ["iipi global llp noida", "IIPI Global LLP"],
    ];
    if (!!val) {
        for (let i = 0; i < mapper.length; i++) {
            if (val.toLowerCase() === mapper[i][0]) {
                return mapper[i][1];
            }
        }
    }
    return val;
};

const _camelCaseToSnakeCase = (str) => {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

const _categoryMatcher = ({ categoryTree, categoryName }) =>
    new Promise((resolve, reject) => {
        try {
            if (typeof categoryTree !== "object" || typeof categoryName !== "string") throw new Error("Malformed argument received");
            if (categoryTree?.name.toUpperCase() === categoryName.toUpperCase()) resolve(true);
            else if (categoryTree?.parent_category) return _categoryMatcher({ categoryTree: categoryTree?.parent_category, categoryName });
            else reject(false);
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });

const _isNonEmptyArray = (arr) => arr && Array.isArray(arr) && arr.length > 0;

const _allowQCReportEdit = (is_report_editable, allowed_user_id) => {
    let response = {
        is_report_editable: false,
        end_qc_edit_time: null,
    };
    if (is_report_editable && (getUserPriorityRole() === "admin" || getUserData().id === allowed_user_id)) {
        let now_time = new Date().getTime();
        let edit_start_time = new Date(is_report_editable).getTime();
        if (now_time - edit_start_time < 24 * 60 * 60 * 1000) {
            response.is_report_editable = true;
            response.end_qc_edit_time = edit_start_time + 24 * 60 * 60 * 1000;
        }
    }
    return response;
};

const _isGiaUser = () => {
    return getUserData()?.roles?.includes("ai_designer") || getUserData()?.roles?.includes("ai_buyer") || getUserData()?.roles?.includes("admin") || getUserData()?.roles?.includes("gia_plan") || getUserData()?.roles?.includes("gia_plan_admin") || getUserData()?.roles?.includes("gia_plan_pro") || getUserData()?.roles?.includes("gia_plan_pro_max");
};

const _currencySymbolFromCode = (currency_code) => _currencyMap.filter((c) => c.code === currency_code);

const _secToString = (duration) => {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;

    return ret;
};

export { __slashNsplitter, _addressLine, _allowQCReportEdit, _axiosPendingReqs, _b64toBlob, _buildParams, _camelCaseToSnakeCase, _capitalize, _categoryMatcher, _checkForPending, _checkKeyValue, _closeImageCropper, _computeDaysFromTS, _currencySymbolFromCode, _debounce, _existAndGetLsValue, _fuzzySearch, _generateUUID, _getApiResponseWithSearch, _getConfig, _getCookieValue, _getEntityName, _getFinancialYear, _getLogData, _getOrSetIpLocation, _incRetryCountPendingReqinIDB, _infiniteLoad, _infiniteScrollListener, _inView, _inWords, _isGiaUser, _isJsonString, _isNonEmptyArray, _isNumber, _lazyloadImages, _loadMore, _logBackendEvent, _maintainBackState, _makeFloat, _makeStateObject, _matchUniqueIDs, _nestedJSONStringToJSON, _pageEnterTime, _pageExitTime, _paginationCall, _positiveNumCondenser, _positiveNumCondenserMil, _postApiResponseWithSearch, _preserveBackState, _price_in_words, _promisedSetState, _qcAllRights, _removeFromLSwithUniqueID, _removeKeyFromIDB, _removePendingReqFromIDB, _renderLeadTemplate, _resizeImageFile, _sanitizeObject, _sanitizeWEevent, _secToString, _setCookieValue, _setLsValues, _setPendingReqToIDB, _timedPopup, _universal_logger, _universal_logger_v2, _withDecimal, _zi, _zo, axiosIntercept, getBaseUrl, getEnvType, getGiaData, getLsValues, getOldPortalLink, getRolePriority, getUserData, getUserPriorityRole, parseJwt };
