import React from "react";

// custom css
import "./Loader.css";

export default class Loader extends React.Component {
    state = {
        isMobile: typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1,
    };
    render() {
        let { isMobile } = this.state,
            { position, zIndex } = this.props;
        return (
            <div className={`${position ? position : "pF"} bgF wp100 hp100 t0 ${isMobile || (!!position && position !== "pF") ? "l0" : "l250"} bgF ${zIndex ? zIndex : "z20"}`} style={{ width: isMobile || (!!position && position !== "pF") ? "100%" : "calc(100% - 15.625rem)" }}>
                <div className="aiC dF fdC hp100 jcC wp100 ghost-card-pulsate">
                    <img src="https://geniemode-dev-images.s3.ap-south-1.amazonaws.com/assets/MainLogoMinified.png" alt="geniemode logo" className="dB w200 mA pA" />
                </div>
            </div>
        );
    }
}
